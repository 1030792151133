<template>
    <modal ref="modalVerImagen" tamano="modal-xl">
        <div class="image-viewer text-center">
            <img :src="adjunto" alt="image-viewer" style="max-width: 100%; max-height: calc(100vh - 300px);" />
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return {
            adjunto: null,
        }
    },

    methods:{
        async toggle(adjunto){
            this.adjunto = adjunto;
            this.$refs.modalVerImagen.toggle();
        },
    }
}
</script>
<style lang="scss" scoped>
.type-image-modal{
	object-fit: cover;
	max-width: 100%;
	max-height: 400px;
}
</style>